<template>
  <div class="cabinet-wrap">
    <div class="cabinet-box">
      <el-form
        size="small"
        class="c-rule-form"
        ref="ruleForm"
        label-position="left"
        label-width="100px"
        :model="ruleForm"
        :rules="rules"
      >
        <el-form-item label="版本名称" prop="versionName">
          <el-select
            remote
            filterable
            style="width: 280px"
            v-model="ruleForm.versionName"
            placeholder="请输入版本名称"
            :disabled="isSee"
            :remote-method="remoteMethod"
            :loading="loading"
            clearable
            @change="handleCustomerName"
          >
            <el-option v-for="item in customeOptions" :key="item.id" :label="item.versionName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本编码" prop="versionNo">
          <el-input disabled placeholder="请输入版本编码" v-model="ruleForm.versionNo"></el-input>
        </el-form-item>
        <el-form-item label="升级应用" prop="upgradeApplyName">
          <el-input disabled placeholder="请输入升级应用" v-model="ruleForm.upgradeApplyName"></el-input>
        </el-form-item>
        <el-form-item label="升级包URL" prop="url">
          <el-input disabled placeholder="请输入升级包URL" v-model="ruleForm.url"></el-input>
        </el-form-item>
        <el-form-item label="适用柜机" prop="applyName">
          <el-input disabled placeholder="请输入适用柜机" v-model="ruleForm.applyName"></el-input>
        </el-form-item>
        <el-form-item label="预设升级时间" prop="startTimeStr">
          <el-date-picker
            type="datetime"
            placeholder="请选择日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="ruleForm.startTimeStr"
            :disabled="isSee"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="柜机列表" prop="lockerBindReqList">
          <el-button plain type="success" :disabled="!ruleForm.versionName || isSee || ruleForm.universal" @click="choiceAdd"
            >选择柜机</el-button
          >
          <span style="margin-left: 12px" @click="onSelected"
            >已选柜机数：<span style="color: #85cf26">{{ ruleForm.lockerBindReqList.length }}</span></span
          >
        </el-form-item>
        <el-form-item label="  " prop="universal">
          <el-checkbox v-model="ruleForm.universal" :disabled="isSee" @change="universalChange"></el-checkbox>应用同类型的所有柜机（包含未激活柜机）
        </el-form-item>
      </el-form>
    </div>
    <div class="creates-footer">
      <div class="c-ruleform-but">
        <div v-if="!isSee" >
          <el-button size="small" type="success" @click="submitForm('ruleForm')">确定</el-button>
          <el-button size="small" @click="resetForm('ruleForm')">取消</el-button>
        </div>
        <el-button style="width:144px" v-else size="small" type="success"  @click="resetForm('ruleForm')">返回</el-button>
      </div>
    </div>

    <!-- 选择柜机/已选柜机 -->
    <Dialog width="978px" :title="digTitle" :visible="visible" @close="closeDialog">
      <el-form
        size="small"
        class="search-pannel"
        ref="ruleFormDevice"
        label-position="left"
        :model="formDevice"
        @submit.native.prevent
      >
        <el-form-item label="柜机编码：">
          <el-input
            placeholder="请输入柜机编码"
            oninput="if (value.length>5) value=value.slice(0,20)"
            v-model="formDevice.lockerCode"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属企业：">
          <el-input
            placeholder="请输入所属企业"
            v-model="formDevice.customerName"
            oninput="if (value.length>5) value=value.slice(0,30)"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属国家：">
          <el-select clearable placeholder="请选择所属国家" v-model="formDevice.country" @change="changeactType">
            <el-option
              v-for="item in areaCodeList"
              :key="item.areaCode"
              :label="item.areaName"
              :value="item.areaCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="柜机类型：">
          <el-select disabled multiple clearable placeholder="请选择柜机类型" v-model="formDevice.productCodeList" @change="changeactType">
            <el-option v-for="item in applyList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" native-type="submit" @click="onSearch">搜索</el-button>
          <el-button size="small" @click="onReset('ruleFormDevice')">重置</el-button>
        </el-form-item>
      </el-form>
      <Table
        max-height="500"
        style="margin-top: 20px"
        ref="tableRef"
        row-key="lockerCode"
        :loading="tableLoading"
        :hidden="!tableData.length"
        :header-cell-style="{ background: '#F7F8FA ' }"
        :columns="columns"
        :data="tableData"
        :total="pageParams.total"
        :page="pageParams.pageNum"
        :limit="pageParams.pageSize"
        @select="handleSelectionChange"
        @select-all="handleSelectionAll"
        @pagination="updatePage"
      >
        <el-table-column
          slot="selection"
          type="selection"
          fixed="left"
          :reserve-selection="true"
          :selectable="checkSelectable"
        ></el-table-column>
      </Table>
      <el-row style="margin-top: 10px" type="flex" justify="end">
        <el-button @click="visible = false" size="small">取 消</el-button>
        <el-button type="success" @click="visible = false" size="small">确认</el-button>
      </el-row>
    </Dialog>
  </div>
</template>

<script>
import { seeVersionList, getcountryList, upgradeTaskQuery, addUpgradeTask, taskDetail } from '@/api/modules/operation'
import { getVendorList } from '@/api/modules/device'
import Table from '@/components/TablePage'
import Dialog from '@/components/Dialog'
export default {
  name: 'newCabinet',
  components: {
    Table,
    Dialog
  },
  data() {
    return {
      ruleForm: {
        versionId: '', // 版本id
        versionNo: '', // 版本编号
        versionName: '', // 版本名称
        upgradeApplyName: '',
        upgradeApplyCode: '',
        url: '',
        applyName: '',
        startTimeStr: '', // 预设升级时间
        lockerBindReqList: [], // 绑定柜机集合
        ruleForm: false,
        universal: false
      },
      loading: false,
      tableLoading: false,
      visible: false,
      tableData: [],
      applyList: [],
      columns: [
        { label: '柜机编码', prop: 'lockerCode' },
        { label: '柜机类型', prop: 'lockerTypeName' },
        { label: '所属企业', prop: 'customerName' }, // 0-未开始；1-进行中；2-已结束
        { label: '所属国家', prop: 'areaName' },
        { label: '现有版本', prop: 'version' }
      ],
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      customeOptions: [],
      areaCodeList: [],
      formDevice: {
        lockerCode: '',
        productCodeList: [],
        customerName: '',
        country: ''
      },
      isSelectedStatus: false,
      digTitle: '',
      pickerOptions: {
        disabledDate(time) {
          return new Date(time).getTime() < new Date(new Date().toLocaleDateString()).getTime()
        }
      }
    }
  },
  created() {
    this.getVendor('applyList', 'productType')
  },
  mounted() {
    if (this.$route.query.id) {
      this.getTaskDetail()
    }
  },
  computed: {
    isSee() {
      if (this.$route.query.id) {
        return true
      }
      return false
    },
    rules() {
      return {
        versionName: [
          { required: true, message: '请输入版本名称', trigger: 'change' },
          { max: 30, message: '版本名称最多30个字符', trigger: 'blur' }
        ],
        versionNo: [{ required: true, message: '请输入版本编码', trigger: 'change' }],
        upgradeApplyName: [{ required: true, message: '请输入升级应用', trigger: 'change' }],
        url: [{ required: true, message: '请输入升级包URL', trigger: 'change' }],
        applyName: [{ required: true, message: '请输入适用柜机', trigger: 'change' }],
        startTimeStr: [{ required: true, message: '请选择预设升级时间', trigger: 'change' }],
        lockerBindReqList: [{ required: !this.ruleForm.universal, message: '请选择柜机列表', trigger: 'blur' }]
      }
    },
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return
        const { universal, ...data } = this.ruleForm
        addUpgradeTask({
          universal: universal ? 2 : 1,
          ...data
        }).then((res) => {
          console.log('addUpgradeTask', res)
          if (res.code === '000000000') {
            this.$message.success(res.msg)
            this.$router.push({ name: 'versionUpgrade' })
          } else {
            this.$message.warning(res.msg)
          }
        })
      })
    },
    // 获取参数
    getParams(flag) {
      const getParam = { ...this.formDevice }
      getParam.pageNum = flag ? 1 : this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    onSearch(flag, type) {
      const selectList = this.ruleForm.lockerBindReqList.map((item) => item.lockerCode) || []
      console.log(this.ruleForm)
      const data = {
        isSelected: type ? true : this.isSee ? true : false,
        versionNo: this.ruleForm.versionNo,
        lockerUpgradeAppCode: this.ruleForm.upgradeApplyCode,
        selectedLockerCodes: type ? selectList : this.isSee ? selectList : [],
        // productCodeList: this.ruleForm.applyType.split(','),
        ...this.getParams(flag)
      }
      this.tableLoading = true
      upgradeTaskQuery(data)
        .then((res) => {
          const { records, pageNum, pageSize, total = 0 } = res.data
          this.tableData = records || []
          this.pageParams = { pageNum, pageSize, total }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    onReset(formName) {
      this.$refs[formName].resetFields()

      this.formDevice = {
        lockerCode: '',
        productCodeList: this.formDevice.productCodeList,
        customerName: '',
        country: ''
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.$router.push({ name: 'versionUpgrade' })
    },
    // 选择柜机
    choiceAdd() {
      this.digTitle = '选择柜机'
      this.visible = this.isSee ? false : true
      this.getcountry()
      this.onSearch(false, '')
    },
    // 已选柜机
    onSelected() {
      if (!this.ruleForm.lockerBindReqList.length) {
        this.$message.warning('请先选择柜机列表')
        return
      }
      this.digTitle = '已选柜机'
      this.visible = true
      this.onSearch(true, 'select')
    },
    // 版本名称搜索
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          const data = {
            pageNum: 1,
            pageSize: 9999,
            versionName: query
          }
          seeVersionList(data).then((res) => {
            this.customeOptions = res.data || []
          })
        }, 150)
      } else {
        this.customeOptions = []
      }
    },
    handleCustomerName(val) {
      this.ruleForm.versionName = this.customeOptions.reduce((t, v) => {
        if (v.id === val && v.versionName) {
          console.log(v)
          this.ruleForm.versionNo = v.versionNo
          this.ruleForm.upgradeApplyName = v.upgradeApplyName
          this.ruleForm.upgradeApplyCode = v.upgradeApplyCode
          this.ruleForm.url = v.url
          this.ruleForm.applyName = v.applyName
          this.ruleForm.versionId = v.id
          this.ruleForm.applyType = v.applyType
          this.formDevice.productCodeList = v.applyType.split(',')
          console.log(v.applyType)
          t = v
        }
        return t.versionName || t
      }, '')
      console.log(this.ruleForm.versionName)
      this.$refs.tableRef && this.$refs.tableRef.$refs.tableRef.clearSelection()
      this.ruleForm.lockerBindReqList = []
    },
    closeDialog() {
      this.visible = false
    },
    // 获取下拉列表
    getVendor(fn, type) {
      getVendorList({ pageNum: 1, pageSize: 9999, entryVariable: type }).then((res) => {
        const { records } = res.data || []
        this[fn] = records || []
        this[fn].forEach((item) => {
          item.value = item.itemCode
          item.label = item.itemValue
        })
      })
    },
    // 获取城市列表
    getcountry(fn, type) {
      getcountryList().then((res) => {
        this.areaCodeList = res.data || []
        this.areaCodeList.forEach((item) => {
          item.value = item.areaCode
          item.label = item.areaName
        })
      })
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.onSearch()
      }
    },
    // 获取详情
    getTaskDetail() {
      taskDetail({ upgradeTaskId: this.$route.query.id }).then((res) => {
        this.ruleForm = { ...res.data, universal: res.data.universal === 2 }
        this.tableData = this.ruleForm.lockerBindReqList || []
      })
    },
    // 处理select
    changeactType() {
      this.onSearch()
    },
    // 处理分页选中
    handleSelectionChange(selection, row) {
      if (!row.versionWhetherReasonable && row.version) {
        this.$alert('所选柜机的版本号比当前版本要高，请重新校对后选择', '提示', {
          confirmButtonText: '我知道了',
          callback: (action) => {
            this.$refs.tableRef.$refs.tableRef.toggleRowSelection(row)
          }
        })
      } else {
        const arr = selection || []
        this.ruleForm.lockerBindReqList = arr
      }
    },
    // 处理全选
    handleSelectionAll(selection) {
      if (this.isSee) return
      const flag = selection.some((item) => item.versionWhetherReasonable === false && item.version)
      if (flag) {
        this.$alert('所选柜机的版本号比当前版本要高，请重新校对后选择', '提示', {
          confirmButtonText: '我知道了',
          callback: (action) => {
            this.$refs.tableRef.$refs.tableRef.clearSelection()
          }
        })
      } else {
        const arr = selection || []
        this.ruleForm.lockerBindReqList = arr
      }
    },
    // 禁用选中
    checkSelectable(row) {
      if (this.$route.query.id && row.lockerCode) {
        return false
      }
      return true
    },
    universalChange() {
      this.ruleForm.lockerBindReqList = []
      this.$refs.tableRef && this.$refs.tableRef.$refs.tableRef.clearSelection()
    }
  }
}
</script>

<style lang="scss" scoped>
.cabinet-wrap {
  position: relative;
  min-height: calc(100vh - 120px);
  .cabinet-box {
    padding: 30px 0 0 120px;
    box-sizing: border-box;
    position: relative;
    .c-rule-form {
      width: 380px;
      display: grid;
      gap: 20px 20px;
    }
  }
  .creates-footer {
    border-top: 2px solid #ebedf0;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 76px;
    width: 100%;
    background: #ffffff;
    .c-ruleform-but {
      display: flex;
      justify-content: center;
    }
  }
}
.el-date-editor.el-input{
  width: 280px;
}
</style>